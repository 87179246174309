document.querySelectorAll('article').forEach((el) => {
  el.addEventListener('click', () => {
    document.querySelectorAll('article').forEach((el) => {
      el.classList.remove('hovered');
    });
    el.classList.add('hovered');
  });

  el.addEventListener('mouseenter', () => {
    document.querySelectorAll('article').forEach((el) => {
      el.classList.remove('hovered');
    });
    el.classList.add('hovered');
  });

  el.addEventListener('mouseleave', () => {
    el.classList.remove('hovered');
  });
});

document.getElementById('year').innerHTML = new Date().getFullYear();

document.querySelectorAll('section').forEach((el) => {
  el.style['scroll-margin-top'] = document.querySelector('header').offsetHeight + 'px';
});

document.querySelectorAll('nav a').forEach((el) => {
  el.addEventListener('click', () => {
    document.querySelector(`[data-id="${el.getAttribute('href').replace('#', '')}"]`).scrollIntoView({ behavior: 'smooth' });
  });
});

document.querySelector('[data-id="qui-suis-je"]').style['padding-top'] = document.querySelector('header').offsetHeight + 'px';
